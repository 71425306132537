import { Fragment } from "react";

import {
  ConfigurationInitializer,
  ConfigurationRenderer,
  ConfigurationController,
  HeartbeatController,
} from "@plansoft-configuration-renderer/framework";

import { useBackendTranslations } from "@plansoft-configuration-renderer/localization";

import { ConfigurationBackdrop } from "@plansoft-configuration-renderer/components";

import { SettingsSync } from "@plansoft-configuration-renderer/layout";

import { CustomRenderer } from "./CustomRenderer";
import { ConfigurationStateSpy } from "./ConfigurationStateSpy";

export interface MinimalRendererProps {
  /**
   * Configuration object id
   */
  cfgObjectId: string;
  /**
   * Configuration language / locale code
   */
  language: string;
  /**
   * The base URL must be mapped by the proxy
   */
  baseUrl: string;
  /**
   * Use a specific layout, only works for the root cfg object
   */
  layoutId?: string;
  sessionId?: string;
}

/**
 * This is the base setup for the renderer
 *
 * - the `CsCfgObjectController` starts the configuration
 * - the `ConfigurationController` provides the necessary state management
 */
export function MinimalRenderer(props: MinimalRendererProps): JSX.Element {
  // initializes the translations
  const isReady = useBackendTranslations(
    {
      basePath: props.baseUrl,
    },
    {
      localeId: props.language,
    },
  );

  // see setupProxy.js in src folder
  // in production this could be loaded from the database et
  const { baseUrl, ...startOptions } = props;

  // if any start option changes, reset the ConfigurationController
  const configurationResetKey = Object.values(props).join("#");

  if (!isReady) {
    return <Fragment />;
  }

  return (
    <ConfigurationController baseUrl={baseUrl} key={configurationResetKey}>
      {/* ConfigurationStarter starts to configuration but renders nothing. Also see ConfigurationResumer */}

      {startOptions.sessionId ? (
        <ConfigurationInitializer
          sessionId={startOptions.sessionId}
          localeId={startOptions.language}
        />
      ) : (
        <ConfigurationInitializer
          cfgObjectId={startOptions.cfgObjectId}
          localeId={startOptions.language}
        />
      )}

      {/* ConfigurationRenderer prevents the configuration from rendering when there is not cfg object yet */}
      <ConfigurationRenderer>
        {/* Renders nothing, but keeps the session alive */}
        <HeartbeatController interval={60e3} />
        {/* Sync settings to redux store, omit if not needed */}
        <SettingsSync />
        {/* Display loading spinner when requests are open */}
        <ConfigurationBackdrop />
        {/* Renders the configuration */}
        <CustomRenderer />
        {/* Spy on configuration state changes */}
        <ConfigurationStateSpy />
      </ConfigurationRenderer>
    </ConfigurationController>
  );
}
