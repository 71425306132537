import { useEffect, useState } from "react";
import { Alert, Grid } from "@mui/material";

import {
  GatewayDest,
  GatewayProvider,
} from "@plansoft-configuration-renderer/gateway";

import { MinimalAppContext } from "./MinimalAppContext";
import { MinimalRenderer } from "./MinimalRenderer";
import { CustomSubconfigurationNavigationCtxProvider } from "./ConfigurationComponents/CustomSubconfigurationNavigation/CustomSubconfigurationNavigationCtx";

import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";

export function MinimalApp(): JSX.Element {
  const urlParams = new URLSearchParams(window.location.search);
  const cfgObjectId = urlParams.get("cfgObjectId");
  const sessionId = urlParams.get("sessionId");
  const localeId = urlParams.get("localeId");
  const callbackUrl = urlParams.get("callBackUrl");
  const userToken = urlParams.get("userToken");
  const loadCfgStateId = urlParams.get("loadCfgStateId");

  const [cfgSessionId, setCfgSessionId] = useState();
  // const [showBackdrop, setShowBackdrop] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get("/api/schmersal/configuration/load", {
        params: {
          LocaleId: localeId,
          SessionId: sessionId,
          CfgObjectId: cfgObjectId,
          CallbackUrl: callbackUrl,
          UserToken: userToken,
          LoadCfgStateId: loadCfgStateId,
        },
      })
      .then(function (response) {
        console.log(response);
        setCfgSessionId(response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        //TODO{sh}: handle Error
        console.log(error);
        setIsLoading(true);
      });
  }, [
    cfgObjectId,
    sessionId,
    localeId,
    callbackUrl,
    userToken,
    loadCfgStateId,
  ]);

  if (isLoading && !cfgSessionId) {
    return <Alert severity="error">Configuration not found</Alert>;
  }

  return (
    <MinimalAppContext>
      <GatewayProvider>
        <CustomSubconfigurationNavigationCtxProvider>
          <CssBaseline />
          <Grid container spacing={10}>
            <Grid item xs={8} sx={{ position: "relative" }}>
              <MinimalRenderer
                baseUrl={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
                cfgObjectId={cfgObjectId ?? "CFG_N_PROGRAM"}
                sessionId={cfgSessionId ?? undefined}
                language={localeId ?? "en-US"}
              />
            </Grid>
            <Grid item xs={4}>
              <GatewayDest
                name="SideView"
                style={{ height: "100%", width: "auto" }}
                childStyle={{ height: "100%", display: "flex" }}
              />
            </Grid>
          </Grid>
        </CustomSubconfigurationNavigationCtxProvider>
      </GatewayProvider>
    </MinimalAppContext>
  );
}
