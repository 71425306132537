import { ReactNode } from "react";

import Box from "@mui/material/Box";
import type { SxProps } from "@mui/system";

import { useCsAttribute } from "@plansoft-configuration-renderer/framework";

export interface AttributeWrapperProps {
  children: ReactNode;
}

/**
 * Generic wrapper for attributes
 *
 * Wraps attribute headline and control element
 */
export function CustomAttributeWrapper(
  props: AttributeWrapperProps,
): JSX.Element {
  const { isAllowed, path } = useCsAttribute();

  const notAllowedProps: SxProps = isAllowed
    ? {}
    : {
        pointerEvents: "none",
        opacity: 0.75,
      };

  return (
    <Box id={path} className="attribute-wrapper" sx={{ ...notAllowedProps }}>
      {props.children}
    </Box>
  );
}
