import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useConfigurationObjectName } from "@plansoft-configuration-renderer/framework";

export function CustomConfigurationHeadline(): JSX.Element {
  const labelText = useConfigurationObjectName();

  return (
    <Box my={2}>
      <Typography variant="h3">{labelText}</Typography>
    </Box>
  );
}

CustomConfigurationHeadline.displayName = "CustomConfigurationHeadline";
