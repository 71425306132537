import { Fragment, useState } from "react";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import {
  SettingsBackupRestore,
  FormatListBulleted,
  Close,
} from "@mui/icons-material";
// import { faUndo, faList, faXmark } from "@fortawesome/free-solid-svg-icons";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
  useCsCfgObject,
  useCsConfigurationApi,
  useCsConfigurationMeta,
} from "@plansoft-configuration-renderer/framework";

import { useConfigurationStatus } from "@plansoft-configuration-renderer/components";

import { useUiTranslation } from "@plansoft-configuration-renderer/localization";

import { useConfigurationSummaryAndMore } from "../ConfigurationSummary/useConfigurationSummaryAndMore";

/**
 * @todo This is too complicated!
 * @returns
 */
export function ConfigurationSpeedDial(): JSX.Element {
  const cfgObject = useCsCfgObject();

  const { isComplete } = useConfigurationStatus(cfgObject);

  const summaryDisplay = useConfigurationSummaryAndMore();

  const { resetCurrentCfgObject } = useCsConfigurationApi();

  const { t } = useUiTranslation();

  const [isOpen, setIsOpen] = useState(true);

  const { isStarted } = useCsConfigurationMeta();

  if (!isStarted) {
    return <Fragment />;
  }

  const tooltipOpen = undefined;

  // ⚠️ Do not place anything except SpeedDialAction in the SpeedDial ⚠️
  return (
    <Fragment>
      <SpeedDial
        ariaLabel="Save / load / reset"
        open={isOpen}
        sx={{
          position: "absolute",
          bottom: 16,
          right: -60,
          backgroundColor: isComplete ? "success" : "warning",
        }}
        onClick={() => setIsOpen(!isOpen)}
        icon={<Close className={isOpen ? "iconOpen" : ""} />}
      >
        <SpeedDialAction
          icon={<SettingsBackupRestore />}
          tooltipTitle={t("Reset configuration")}
          tooltipOpen={tooltipOpen}
          onClick={(e) => {
            e.stopPropagation();
            resetCurrentCfgObject();
          }}
        />
        {isStarted && (
          <SpeedDialAction
            icon={<FormatListBulleted />}
            tooltipTitle={t("Show additional information")}
            tooltipOpen={tooltipOpen}
            onClick={(e) => {
              e.stopPropagation();
              summaryDisplay.showDialog();
            }}
          />
        )}
      </SpeedDial>
      {summaryDisplay.dialogElement}
    </Fragment>
  );
}
