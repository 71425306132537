import { ReactNode, useContext, useEffect, useState } from "react";

import { Box, Alert, AlertTitle } from "@mui/material";
import { ProductInformation } from "../../components/ProductInformation/ProductInformation";
import { useSessionId } from "@plansoft-configuration-renderer/framework";
import { getCfgProductInformation } from "../../components/ProductInformation/GetCfgProductInformation";
import { CustomThumbnailArticlesControl } from "../Thumbnail/CustomThumbnailArticlesControl";
import { CustomSubconfigurationNavigationCtx } from "../../ConfigurationComponents/CustomSubconfigurationNavigation/CustomSubconfigurationNavigationCtx";

// import { useRecoilValueLoadable } from "recoil";
type IndividualComponentProps = {
  /**
   * Can be used to distinguish the type of individual component
   */
  variant?: string;
  /**
   * Custom data object provided by the layout editor
   */
  custom?: Record<string, unknown>;
  /**
   * Child nodes are not yet supported for individual components
   */
  children?: ReactNode;
};

export function CustomIndividualControl(
  props: IndividualComponentProps,
): JSX.Element {
  const { variant = "product_information", custom = {}, children } = props;

  const [getterUrl, setGetterUrl] = useState<string>("getHousingInfo");
  const [productInformation, setProductInformation] = useState();

  const cfgSessionId = useSessionId();

  const { SNActivePath } = useContext(CustomSubconfigurationNavigationCtx);

  useEffect(() => {
    if (SNActivePath?.includes("XAS_POSITION")) {
      setGetterUrl("getPositionInfo");
    }
    if (SNActivePath?.includes("XAS_HOUSING")) {
      setGetterUrl("getHousingInfo");
    }
    if (SNActivePath?.includes("XAS_ACCESSORIES")) {
      setGetterUrl("getAccessoriesInfo");
    }
  }, [SNActivePath]);

  useEffect(() => {
    getCfgProductInformation({
      cfgSessionId,
      getterUrl,
      positionPath: SNActivePath,
    }).then((res) => {
      if (res?.output) {
        console.log(JSON.parse(res?.output));
        setProductInformation(JSON.parse(res?.output));
      }
    });
  }, [getterUrl, cfgSessionId, SNActivePath, children]);

  /*const { contents: productInformationResult } = useRecoilValueLoadable(
    productInformationQuery({ cfgSessionId, getterUrl }),
  );*/

  // const strProductInfo = productInformationResult?.output;
  /*const parsedProductInfo = useMemo(() => {
      if (strProductInfo) {
        return JSON.parse(strProductInfo) as IProductInformation;
      }
      return;
    }, [strProductInfo]);*/

  switch (variant) {
    case "product_information":
      return <ProductInformation data={productInformation ?? {}} />;

    case "schmersal_thumbnail_articles":
      return <CustomThumbnailArticlesControl />;

    default:
      console.error(
        "could not render individual control, check if the variant in the layout editor is set correctly",
        props,
      );
      return (
        <Box my={2}>
          <Alert>
            <AlertTitle>{variant}</AlertTitle>
            <code>{JSON.stringify(custom, null, 2)}</code>
          </Alert>
        </Box>
      );
  }
}
