import { Grid } from "@mui/material";
import { CsValueController } from "@plansoft-configuration-renderer/framework";
import {
  ThumbnailContent,
  ThumbnailImage,
  useThumbnailControlLayoutProps,
  useVisibleAttributeValues,
} from "@plansoft-configuration-renderer/components";

import { CustomThumbnailItem } from "./CustomThumbnailItem";

export function CustomThumbnailArticlesControl(): JSX.Element {
  const values = useVisibleAttributeValues();

  const { gridDirection, gridBreakpoints } = useThumbnailControlLayoutProps();

  return (
    <Grid container direction={gridDirection} spacing="0.625rem">
      {values.map((value) => {
        return (
          <Grid item {...gridBreakpoints} key={value.id}>
            <CsValueController value={value}>
              <CustomThumbnailItem>
                <ThumbnailImage imageHeight={250}></ThumbnailImage>
                <ThumbnailContent>
                  {value.properties?.ArticleDescription.value}
                </ThumbnailContent>
              </CustomThumbnailItem>
            </CsValueController>
          </Grid>
        );
      })}
    </Grid>
  );
}
