import {
  AttributeStrategy,
  LayoutComponentProvider,
  LayoutControlType,
  ControlComponents,
  ConfigurationComponents,
  ConfigurationComponentType,
  AttributeComponents,
  AttributeBaseType,
  AttributeComponentType,
} from "@plansoft-configuration-renderer/components";

import { CustomIndividualContainer } from "./ConfigurationComponents/CustomIndividualContainer/CustomIndividualContainer";

import { CustomSubconfigurationNavigation } from "./ConfigurationComponents/CustomSubconfigurationNavigation";
import { DebugDrawer } from "./components/DebugDrawer";
import { ConfigurationSpeedDial } from "./components/ConfigurationSpeedDial/ConfigurationSpeedDial";
import { CustomConfigurationHeadline } from "./ConfigurationComponents/CustomConfigurationHeadline/CustomConfigurationHeadline";
import { CustomAttributeWrapper } from "./AttributeComponents/CustomAttributeWrapper";
import { CustomAttributeHeadline } from "./AttributeComponents/CustomAttributeHeadline";
import {
  LayoutComponentMapping,
  LegacyLayoutGrid,
} from "@plansoft-configuration-renderer/layout";
import { CustomIndividualControl } from "./ControlComponents/CustomIndividualControl/CustomIndividualControl";
import { CustomThumbnailControl } from "./ControlComponents/Thumbnail/CustomThumbnailControl";

const isDevEnvironment =
  window.location.href.includes("dev") ||
  window.location.href.includes("localhost");

// override control components for attributes
const controlComponents = {
  ...ControlComponents, // the default components
  [LayoutControlType.Individual]: CustomIndividualControl, // override individual control
  [LayoutControlType.Thumbnail]: CustomThumbnailControl,
};

// override configuration level components
const configurationComponents = {
  ...ConfigurationComponents,
  [ConfigurationComponentType.ConfigurationHeadline]:
    CustomConfigurationHeadline,
  [ConfigurationComponentType.IndividualContainer]: CustomIndividualContainer,
  [ConfigurationComponentType.SubconfigurationNavigation]:
    CustomSubconfigurationNavigation,
};

// override attribute components
const attributeComponents = {
  ...AttributeComponents,
  [AttributeComponentType.AttributeHeadline]: CustomAttributeHeadline,

  [AttributeBaseType.SingleOption]: CustomAttributeWrapper,
  [AttributeBaseType.MultiOption]: CustomAttributeWrapper,
  [AttributeBaseType.Substructure]: CustomAttributeWrapper,
  [AttributeBaseType.Editable]: CustomAttributeWrapper,
};

const LayoutComponents: LayoutComponentMapping = {
  LayoutGrid: LegacyLayoutGrid,
  flexLayoutBreakpoint: "sm",
};

export function CustomRenderer(): JSX.Element {
  // see properties of LayoutComponentProvider for more information
  return (
    <LayoutComponentProvider
      configurationComponents={configurationComponents}
      attributeComponents={attributeComponents}
      controlComponents={controlComponents}
      layoutComponents={LayoutComponents}
    >
      {/* Attribute strategy renders the components using the layout information */}
      <AttributeStrategy />

      {isDevEnvironment && <DebugDrawer />}
      <ConfigurationSpeedDial />
    </LayoutComponentProvider>
  );
}
