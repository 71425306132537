// import Button from "@mui/material/Button";
import { Suspense, ReactNode } from "react";
import { RecoilRoot } from "recoil";

import { LinearProgress } from "@mui/material";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface MinimalAppContextProps {
  children: ReactNode;
}

export function MinimalAppContext(props: MinimalAppContextProps): JSX.Element {
  return (
    <RecoilRoot>
      <Suspense fallback={<LinearProgress />}>
        <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
          <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
        </MuiLocalizationProvider>
      </Suspense>
    </RecoilRoot>
  );
}
