import { Gateway } from "@plansoft-configuration-renderer/gateway";
import { Fragment, ReactNode } from "react";

import {
  CustomIndividualContainerCtxProvider,
  TIndividualContainerVariants,
} from "./CustomIndividualContainerCtx";

export interface IndividualContainerProps {
  children: ReactNode;
  variant: TIndividualContainerVariants;
}

/**
 * Beams the content of IndividualContainer into the #SideView */
export function CustomIndividualContainer({
  variant,
  children,
}: IndividualContainerProps): JSX.Element {
  if (variant === "SideView") {
    return (
      <CustomIndividualContainerCtxProvider variant={variant}>
        <Gateway into="SideView">{children}</Gateway>
      </CustomIndividualContainerCtxProvider>
    );
  }

  return <Fragment>{children}</Fragment>;
}
