import { lazy, Suspense } from "react";

import type { SubconfigurationNavigationProps } from "@plansoft-configuration-renderer/components";

const CustomSubconfigurationNavigationComponent = lazy(
  () => import("./CustomSubconfigurationNavigation"),
);

/**
 * Lazy loads date picker control
 * @param props
 * @returns
 */
export function CustomSubconfigurationNavigation(
  props: SubconfigurationNavigationProps,
): JSX.Element {
  return (
    <Suspense>
      <CustomSubconfigurationNavigationComponent {...props} />
    </Suspense>
  );
}
