import {
  createTheme,
  PaletteOptions,
  responsiveFontSizes,
} from "@mui/material/styles";

import "./fonts.css";

import { darken } from "@mui/material";
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    black: true;
    gray: true;
    lightgray: true;
  }
}

export const customPalette = {
  primary: {
    main: "#00377B",
    light: "#EAEEF9",
    dark: "rgb(0, 38, 86)",
    contrastText: "#FFF",
  },
  secondary: {
    main: "#00B1EB",
    light: "#BCBCBC",
    dark: "rgba(0, 55, 123, 0.08)",
  },
  info: {
    main: "#00377B",
  },
} satisfies PaletteOptions;

export const customTheme = createTheme({
  palette: customPalette,
  typography: {
    fontFamily: ["BioSans", "sans-serif"].join(","),
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "black",
    },
    h4: {
      fontSize: "1,25rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h5: {
      fontSize: "1rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },

    subtitle1: {
      fontWeight: "bold",
    },
    subtitle2: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ["BioSans", "sans-serif"].join(","),
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "&": {
            top: "0",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&.MuiTab-root": {
            fontFamily: ["BioSans", "sans-serif"].join(","),
            "&.Mui-selected": {
              fontWeight: "bold",
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: ["BioSans", "sans-serif"].join(","),
          lineHeight: 1.5,
          textTransform: "none",
          ":active": {
            backgroundColor: darken(customPalette.primary.main, 0.7),
          },
          "&.Mui-disabled": {
            backgroundColor: customPalette.primary.main,
            color: customPalette.primary.contrastText,
            opacity: 0.65,
          },
        },
        outlined: {
          borderColor: customPalette.primary.main,
          ":hover": {
            color: customPalette.primary.dark,
            borderColor: customPalette.primary.dark,
            backgroundColor: "white",
          },
          ":active": {
            borderColor: darken(customPalette.primary.main, 0.7),
            backgroundColor: "white",
          },
          "&.Mui-disabled": {
            borderColor: "#777",
            color: "#777",
            backgroundColor: "white",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "none",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 0,
        },
        head: {
          borderBottom: "1px solid",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: "#ccc",
            },
          },
          "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
              borderColor: "#ccc",
            },
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: "#66afe9",
              borderWidth: 1,
              boxShadow:
                "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)",
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          ".MuiMenuItem-root": {
            "&.Mui-selected": {
              backgroundColor: customPalette.secondary.dark,
              "&.Mui-focusVisible": {
                backgroundColor: customPalette.secondary.dark,
              },
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: "100%",
          //TODO{sh}: Fix this, needed?
          // [theme.breakpoints.up("md")]: {
          //   minWidth: "50%",
          // },
          // [customTheme.breakpoints.up("lg")]: {
          //   minWidth: "50%",
          // },
          // [customTheme.breakpoints.up("xl")]: {
          //   minWidth: "33%",
          // },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": { borderColor: "#ccc" },
          },
          "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
              borderColor: "#ccc",
            },
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: "#66afe9",
              borderWidth: 1,
              boxShadow:
                "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          height: "2rem",
          margin: 0,
          "& .MuiButtonBase-root": {
            paddingRight: "0.5rem",
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: "1051",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          zIndex: "1052",
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        square: true,
        disableGutters: true,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${customPalette.secondary.light}`,
          borderBottom: `1px solid ${customPalette.secondary.light}`,
          fontWeight: "bold",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid #eee`,
          "&:hover": {
            borderColor: customPalette.primary.main,
          },
          "&.active": {
            borderColor: customPalette.primary.main,
            color: customPalette.primary.main,
            fontWeight: "bold",
            p: {
              fontWeight: "bold",
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          // minHeight: 200,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: ["BioSans", "sans-serif"].join(","),
          fontWeight: "bold",
          color: "black",
        },
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          width: 40,
          height: 40,
        },
        root: {
          ".MuiSpeedDial-actions": {
            marginBottom: -40,
          },
          ".fa-xmark": {
            transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            transform: "rotate(45deg)",
            "&.iconOpen": {
              transform: "none",
            },
          },
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          backgroundColor: customPalette.primary.main,
          color: customPalette.primary.contrastText,
          "&:hover": {
            backgroundColor: customPalette.primary.dark,
            color: customPalette.primary.contrastText,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 50,
          height: 26,
          padding: 0,
          margin: "0.3rem 0",
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(24px)",
              backgroundColor: "white",
              "& + .MuiSwitch-track": {
                backgroundColor: customPalette.primary.main,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.8,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              backgroundColor: "white",
              border: "6px solid white",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              opacity: 0.8,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.8,
            },
          },
          "& .MuiSwitch-thumb": {
            color: "white",
            boxSizing: "border-box",
            width: 22,
            height: 22,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: customPalette.primary.light,
            opacity: 1,
          },
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(customTheme);
