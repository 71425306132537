import { ThemeProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { MinimalApp } from "./renderer/MinimalApp";
import createCache from "@emotion/cache";
import { theme } from "./styles/theme";

const cacheNoPrefixer = createCache({
  key: "noprefixer",
  ...(process.env.NODE_ENV === "development" && { stylisPlugins: [] }),
});

function App(): JSX.Element {
  return (
    <CacheProvider value={cacheNoPrefixer}>
      <ThemeProvider theme={theme}>
        <MinimalApp />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
