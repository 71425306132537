import { createContext, ReactNode, useState } from "react";

interface CustomSubconfigurationNavigationCtxType {
  SNActivePath: string;
  setSNActivePath: (path: string) => void;
}

export const CustomSubconfigurationNavigationCtx =
  createContext<CustomSubconfigurationNavigationCtxType>({
    SNActivePath: "",
    setSNActivePath: () => {},
  });

/** Provides the IndividualContainer variant as context to all components below.
 * Required for example to figure out if tabs are rendered inside the individual container*/
export function CustomSubconfigurationNavigationCtxProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [SNActivePath, setSNActivePath] = useState("");

  return (
    <CustomSubconfigurationNavigationCtx.Provider
      value={{
        SNActivePath,
        setSNActivePath,
      }}
    >
      {children}
    </CustomSubconfigurationNavigationCtx.Provider>
  );
}
