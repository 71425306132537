import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import { DebugOptionsSelector } from "@plansoft-configuration-renderer/components";
import { useState } from "react";
// import { DownloadIcon } from "../components/customIcons/DownloadIcon";
import {
  useCsCfgObject,
  useCsConfigurationApi,
} from "@plansoft-configuration-renderer/framework";
import RefreshIcon from "@mui/icons-material/Refresh";

export function DebugDrawer(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const cfgObject = useCsCfgObject();
  const { resetRootCfgObject, saveConfiguration } = useCsConfigurationApi();

  async function handleDownloadState(
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> {
    e.stopPropagation();
    const response = await saveConfiguration();
    const timestamp = Date.now();
    const identifier = cfgObject.id;

    const fileContent = response.state;
    const fileBlob = new Blob([fileContent], { type: "text/plain" });
    const fileName = `configuration-state-${identifier}-${timestamp}.json`;

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(fileBlob);
    downloadLink.download = fileName.replace(/[^a-zA-Z0-9_]/g, "_");
    downloadLink.click();
    downloadLink.remove();
  }

  return (
    <>
      <Box position="fixed" right="20px" top="20px" zIndex={99999}>
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => setIsOpen(!isOpen)}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Drawer
        variant="temporary"
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DebugOptionsSelector />
        <Box p={2} display="flex" flexDirection="column">
          <Button onClick={resetRootCfgObject} startIcon={<RefreshIcon />}>
            Reset Configuration
          </Button>
          <Button
            color={"primary"}
            onClick={handleDownloadState}
            // startIcon={<DownloadIcon />}
          >
            Download State
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
