import { FC, Fragment, ReactNode, useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useTheme,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPhone,
  faEnvelope,
  faUpRightFromSquare,
  faSquareFull,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslatorWithInterpolation } from "@plansoft-configuration-renderer/localization";

interface FooterType {
  email?: LinkType;
  phone?: string;
  description?: string;
}

interface LinkType {
  title?: string;
  src?: string;
}

interface InformationType {
  title: string;
  description?: string;
}

interface InformationGroupType {
  title?: string;
  information?: InformationType[];
  list?: string[];
  listAsHtml?: string;
  links?: LinkType[];
}

export interface IProductInformation {
  imgSrc?: string;
  id?: string;
  name?: string;

  articleNumber?: string;
  ean?: number;
  informationGroups?: InformationGroupType[];

  footer?: FooterType;
}

const formatHtmlListElementToStringArray = (
  HtmlListElement: string,
): string[] => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(HtmlListElement, "text/html");
  const parsedHtmlListItems = parsedHtml.getElementsByTagName("li");

  const stringArray = Array.from(parsedHtmlListItems).map((item) => {
    return item.innerHTML;
  });

  return stringArray;
};

const ProductInformationContent = (props: {
  children?: ReactNode;
}): JSX.Element => {
  const refScrollContainer = useRef(null);

  const [showInformationInDialog, setShowInformationInDialog] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const translate = useTranslatorWithInterpolation();

  useEffect(() => {
    if (refScrollContainer.current === null) return;
    else {
      const resizeObserver = new ResizeObserver(() => {
        //@ts-expect-error - TODO{sh}: Fix this
        const height = refScrollContainer.current.offsetHeight;

        if (height < 150) {
          setShowInformationInDialog(true);
        } else {
          setShowInformationInDialog(false);
        }
      });
      resizeObserver.observe(refScrollContainer.current);
      return () => resizeObserver.disconnect(); // clean up
    }
  }, []);

  return (
    <Box
      ref={refScrollContainer}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
        gridGap: "1rem",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: 10,
        },
      }}
    >
      {!showInformationInDialog && props.children}
      {showInformationInDialog && (
        <Fragment>
          <Button
            onClick={() => setIsVisible(true)}
            startIcon={<FontAwesomeIcon icon={faEye} />}
          >
            {translate(
              "translations.BTN_SHOW_INFORMATION.name",
              "Show all information",
              {},
            )}
          </Button>
          <Dialog open={isVisible} maxWidth="sm">
            <DialogContent sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gridGap: "1rem",
                }}
              >
                {props.children}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsVisible(false)}>
                {translate("translations.BTN_CLOSE.name", "Close", {})}
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </Box>
  );
};

export const ProductInformation: FC<{ data: IProductInformation }> = ({
  data,
}) => {
  const { imgSrc, name, articleNumber, ean, informationGroups, footer } = data;

  const translate = useTranslatorWithInterpolation();

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        padding: "2rem",
        height: "100vh",
        width: "-webkit-fill-available",
        position: "fixed",
        top: 0,
        overflowY: "auto",
        textWrap: "balance",
        wordWrap: "break-word",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant={"h3"}>
          {translate(
            "translations.PRODUCT_INFORMATION_TITLE.name",
            "Produktinformationen",
            {},
          )}
        </Typography>

        {imgSrc && (
          <Box
            component="img"
            src={imgSrc}
            alt={name}
            sx={{
              width: "100%",
              maxHeight: "20vh",
              marginY: "0.5rem",
              marginX: "auto",
              objectFit: "contain",
            }}
          />
        )}

        {name && (
          <Typography variant={"h3"} color={"primary"}>
            {name}
          </Typography>
        )}

        <Box sx={{ paddingBottom: 2 }}>
          {ean && (
            <Typography key={ean}>
              <b>
                {translate(
                  "translations.PRODUCT_INFORMATION_EAN.name",
                  "EAN",
                  {},
                ) + ": "}
              </b>
              {ean}
            </Typography>
          )}
          {articleNumber && (
            <Typography key={articleNumber}>
              <b>
                {translate(
                  "translations.PRODUCT_INFORMATION_ARTICLE_NUMBER.name",
                  "Artikelnummer",
                  {},
                ) + ":  "}
              </b>
              {articleNumber}
            </Typography>
          )}
        </Box>
      </Box>

      <ProductInformationContent>
        {informationGroups?.map((informationGroup, index) => (
          <Grid container key={informationGroup.title + "_" + index}>
            {informationGroup.title && (
              <Grid item xs={12}>
                <Typography variant={"subtitle2"}>
                  {informationGroup.title}
                </Typography>
              </Grid>
            )}

            {informationGroup?.list && informationGroup?.list.length > 0 && (
              <List dense sx={{ padding: 0 }}>
                {informationGroup?.list.map((information, index) => {
                  return (
                    <ListItem
                      sx={{ padding: 0, alignItems: "unset" }}
                      key={information + "_" + index}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "unset", paddingRight: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faSquareFull}
                          style={{
                            marginTop: 0.2,
                            color: theme.palette.primary.main,
                            width: "0.75rem",
                            height: "0.75rem",
                            margin: "0.35rem 0",
                          }}
                        />
                      </ListItemIcon>
                      <Typography>{information}</Typography>
                    </ListItem>
                  );
                })}
              </List>
            )}

            {informationGroup?.listAsHtml && (
              <List dense sx={{ padding: 0 }}>
                {formatHtmlListElementToStringArray(
                  informationGroup?.listAsHtml,
                ).map((information, index) => {
                  return (
                    <ListItem
                      sx={{ padding: 0, alignItems: "unset" }}
                      key={information + "_" + index}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "unset", paddingRight: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faSquareFull}
                          style={{
                            marginTop: 0.2,
                            color: theme.palette.primary.main,
                            width: "0.75rem",
                            height: "0.75rem",
                            margin: "0.35rem 0",
                          }}
                        />
                      </ListItemIcon>
                      <Typography>{information}</Typography>
                    </ListItem>
                  );
                })}
              </List>
            )}

            {informationGroup?.information &&
              informationGroup?.information.length > 0 &&
              informationGroup?.information.map((information, index) => {
                return (
                  <Fragment key={information + "_" + index}>
                    <Grid item xs={6}>
                      <b>{information.title}</b>
                    </Grid>
                    <Grid item xs={6}>
                      {information.description}
                    </Grid>
                  </Fragment>
                );
              })}
            {informationGroup?.links &&
              informationGroup?.links.length > 0 &&
              informationGroup?.links.map((link, index) => {
                return (
                  <Grid item xs={12} key={link + "_" + index}>
                    <Link
                      href={link.src}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faUpRightFromSquare}
                        style={{ paddingRight: "0.5rem" }}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: link.title ?? "" }}
                      />
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        ))}
      </ProductInformationContent>

      {footer && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>{footer.description}</Typography>

          <Link
            href={"mailto:" + footer.email?.src}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ paddingRight: "0.5rem" }}
            />
            <Typography>{footer.email?.title}</Typography>
          </Link>
          {/*<Typography variant={'subtitle1'} color={'primary'}>{footer.email}</Typography>*/}
          <Link
            href={"tel:" + footer.phone}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <FontAwesomeIcon
              icon={faPhone}
              style={{ paddingRight: "0.5rem" }}
            />
            <Typography>{footer.phone}</Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};
