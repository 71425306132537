import { createContext, ReactNode, useContext } from "react";

export type TIndividualContainerVariants = "SideView" | string | null; //extend this when new variants are added

interface useIndividualContainerCtxType {
  variant: TIndividualContainerVariants;
}

const CustomIndividualContainerCtx = createContext<{
  variant: TIndividualContainerVariants;
}>({ variant: null });

/** Provides the IndividualContainer variant as context to all components below.
 * Required for example to figure out if tabs are rendered inside the individual container*/
export function CustomIndividualContainerCtxProvider({
  children,
  variant,
}: {
  children: ReactNode;
  variant: string;
}): JSX.Element {
  return (
    <CustomIndividualContainerCtx.Provider value={{ variant }}>
      {children}
    </CustomIndividualContainerCtx.Provider>
  );
}

export function useIndividualContainerCtx(): useIndividualContainerCtxType {
  return useContext(CustomIndividualContainerCtx);
}
