import { useState, useCallback, lazy, Suspense } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslatorWithInterpolation } from "@plansoft-configuration-renderer/localization";

const ConfigurationSummaryAndMore = lazy(
  () => import("./ConfigurationSummaryAndMore"),
);

interface UseConfigurationSummary {
  showDialog(): void;
  dialogElement: JSX.Element;
}

export function useConfigurationSummaryAndMore(): UseConfigurationSummary {
  const [isVisible, setIsVisible] = useState(false);

  const showDialog = useCallback(() => setIsVisible(true), []);

  const translate = useTranslatorWithInterpolation();

  const dialogElement = (
    <Dialog open={isVisible} maxWidth="xl" fullWidth>
      <DialogContent sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Suspense>
          <ConfigurationSummaryAndMore />
        </Suspense>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsVisible(false)}>
          {translate("translations.BTN_CLOSE.name", "Close", {})}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { dialogElement, showDialog };
}
