import { Grid } from "@mui/material";
import { CsValueController } from "@plansoft-configuration-renderer/framework";
import {
  AttributeControlProps,
  useThumbnailControlLayoutProps,
  useVisibleAttributeValues,
} from "@plansoft-configuration-renderer/components";
import { CustomThumbnailItem } from "./CustomThumbnailItem";

export function CustomThumbnailControl(
  props: AttributeControlProps,
): JSX.Element {
  const values = useVisibleAttributeValues();

  const { gridDirection, gridBreakpoints } = useThumbnailControlLayoutProps();

  return (
    <Grid container direction={gridDirection} spacing="0.625rem">
      {values.map((value) => {
        return (
          <Grid item {...gridBreakpoints} key={value.id}>
            <CsValueController value={value}>
              <CustomThumbnailItem>{props.children}</CustomThumbnailItem>
            </CsValueController>
          </Grid>
        );
      })}
    </Grid>
  );
}
